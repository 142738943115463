import React, { useState } from "react";
import axios from "axios";
import "./App.css";
import title from "./pexels-photoscom-93398.jpg";
import nodata from "./empty-img-gray.png"

// Note: These are not used but need to be in the payload
// device_id: "9876",
// temperature: 22.8,
// humidity: 56.7,
// timestamp: "2023-05-04T14:20:00Z"

const data = {
  device_id: "9876",
  temperature: 22.8,
  humidity: 56.7,
  cO2_grams_per_mile: 410,
  timestamp: "2023-05-04T14:20:00Z",
  route1: {
    name: "route1",
    miles: 200,
    fuel: 0,
    toll: 0,
  },
  route2: {
    name: "route2",
    miles: 100,
    fuel: 0,
    toll: 0,
  },
};

function App() {
  
  const [route1, setRoute1] = useState({
    co2: "",
    miles: 1,
    fuel: 4,
    toll: 0,
    mpg: 12,
  });

  const [route2, setRoute2] = useState({
    co2: "",
    miles: 1,
    fuel: 4,
    toll: 0,
    mpg: 12,
  });
  const [responseData, setResponseData] = useState("");
  const [responseText, setResponseText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Route 1 data: ", route1);
    console.log("Route 2 data: ", route2);
    const newData = {
      ...data,
      cO2_grams_per_mile: 410,
      route1: {
        ...data.route1,
        miles: route1.miles,
        fuel: route1.fuel,
        toll: route1.toll,
        mpg: route1.mpg,
      },
      route2: {
        ...data.route2,
        miles: route2.miles,
        fuel: route2.fuel,
        toll: route2.toll,
        mpg: route2.mpg,
      },
    };
    console.log(newData);
    setIsLoading(true);
    axios
      .post('https://dv0r1r1l90.execute-api.us-east-1.amazonaws.com/dev', newData, {
        headers: {
          "x-api-key": '0EM309BupC2ERmAWSSLbI4o9JpZ1fQo7ajzeM4uV',
        },
      })
      .then((response) => {
        console.log(response.data);
        const responseData = JSON.parse(response.data.body);
        const messageText = responseData.message[0].text;
        setResponseText(messageText);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setResponseText(
          `Oops\n\n error \n\n Please try again, and again, and again, if necessary. \n\n It's beta.`
        );
        setIsLoading(false);
      });
  };

  return (
    <div className="bodyContainer2">
      <div className="topLine">
        <div className="lineRed" />
        <div className="lineOrange" />
      </div>

      <div className="top">
        <div className="schneiderLogo" />
        <div className="pilotLogo" />
      </div>

      <div className="header">
        <img src={title} />
        <div className="text-overlay">
          <h1> Be Environmentally-Friendly!</h1>
          <h2> Determine which route minimizes your carbon emissions</h2>
        </div>
      </div>

      <div className="container2">
        <div className="container3">
          <div className="cardsContainer">
            <div className="cards">
              <div className="card">
                <h2>Route 1</h2>
                <div class="form-group">
                  <label htmlFor="miles">Miles:</label>
                  <input
                    class="form-control"
                    type="text"
                    id="miles"
                    value={route1.miles}
                    onChange={(event) =>
                      setRoute1({ ...route1, miles: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="fuel">Fuel:</label>
                  <input
                    class="form-control"
                    type="text"
                    disabled="true"
                    id="fuel"
                    value={route1.fuel}
                    onChange={(event) =>
                      setRoute1({ ...route1, fuel: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="mpg">mpg:</label>
                  <input
                    class="form-control"
                    type="text"
                    id="mpg"
                    value={route1.mpg}
                    onChange={(event) =>
                      setRoute1({ ...route1, mpg: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="toll">Tolls:</label>
                  <input
                    class="form-control"
                    type="text"
                    disabled="true"
                    id="toll"
                    value={route1.toll}
                    onChange={(event) =>
                      setRoute1({ ...route1, toll: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className="card card2">
                <h2>Route 2</h2>
                <div class="form-group">
                  <label htmlFor="miles">Miles:</label>
                  <input
                    class="form-control"
                    type="text"
                    id="miles"
                    value={route2.miles}
                    onChange={(event) =>
                      setRoute2({ ...route2, miles: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="fuel">Fuel:</label>
                  <input
                    class="form-control"
                    type="text"
                    disabled="true"
                    id="fuel"
                    value={route2.fuel}
                    onChange={(event) =>
                      setRoute2({ ...route2, fuel: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="mpg">mpg:</label>
                  <input
                    class="form-control"
                    type="text"
                    id="mpg"
                    value={route2.mpg}
                    onChange={(event) =>
                      setRoute2({ ...route2, mpg: event.target.value })
                    }
                  />
                </div>
                <div class="form-group">
                  <label htmlFor="toll">Tolls:</label>
                  <input
                    class="form-control"
                    type="text"
                    disabled="true"
                    id="toll"
                    value={route2.toll}
                    onChange={(event) =>
                      setRoute2({ ...route2, toll: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="button-container">
              <button type="submit" onClick={handleSubmit}>
                SEE RESULTS
              </button>
            </div>
          </div>

          <div className="results">
            {isLoading ? (
              <div className="centered">
                <span>
                  OpenAI is determining your most environmentally-friendly
                  route...
                </span>
              </div>
            ) : responseText ? (
              <div className="centered">
                <textarea value={responseText} />
              </div>
            ) : (
              <div className="centered">
                <img src={nodata} />
                <p> Add routes info and generate results </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
